// Imports
@import '../../Styles/variables.scss';
@import '../../Styles/mixins.scss';

.testimonials {
    &-hero {
        background-color: $riverbed;
        background-image: url('/images/hero-testimonials.jpg');
        background-position: 50% 0%;
        color: $text-secondary;
        font-size: 1.6rem;
        height: 35rem;

        .container {
            align-items: flex-end;
            display: flex;
            height: 43rem;
            max-width: 100%;
            padding: 0;
        }

        &__content {
            background-color: $eclipse;
            background-color: #6e796e;
            height: 9rem;
            padding: 0 25px;
            text-align: center;
            width: 100%;
        }

        &__header {
            font-size: 3.5rem;
            font-style: italic;
        }
    }

    &-list {
        margin-top: 10rem !important;
    }

    .testimonial {
        display: flex;
        flex-direction: column;
        text-align: center;

        @include md {
            align-items: center;
            flex-direction: row;
            margin-top: 5rem;

            &:nth-child(even) {
                flex-direction: row-reverse;
                border-top: dotted 0.2rem $nero;
                padding: {
                    bottom: 4rem;
                    top: 4rem;
                }
                // background-color: black;
                // padding: {
                //     top: 30px;
                //     bottom: 30px;
                // }
            }
        }

        &__image {
            border: solid 0.4rem $goldenyellow;
            border-radius: 100%;
            overflow: hidden;
            margin: 0 auto;
            max-width: 50%;

            @include md {
                flex-shrink: 0;
                height: 25rem;
                width: 25rem;
            }

            @include lg {
                height: 30rem;
                width: 30rem;
            }
        }

        &__content {
            @include md {
                padding: {
                    left: 4rem;
                    right: 4rem;
                }
            }
            &-writer {
                font-family: 'Arial';
                font-size: 3rem;
                font-weight: bold;
                font-style: italic;
                color: darkgoldenrod;
            }

            &-text {
                font-size: 2rem;
            }
        }
    }
}
