/*** BREAK POINTS ***/
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

/*** COLORS ***/
$black: #000;
$eclipse: #3C3C3C;
$lightgrey: #C7C7C7;
$lochmara: #327a94;
$goldenyellow: #FFE200;
$nero: #222222;
$riverbed: #555C5F;
$white: #FFF;

/*** FONT COLORS ***/
$text-primary: $eclipse;
$text-secondary: $white;
