// Imports
@import '../../Styles/variables.scss';
@import '../../Styles/mixins.scss';

.about {
    &-hero {
        background-color: $riverbed;
        background-image: url('/images/hero-about.jpg');
        background-position: 47.5% 0%;
        color: $text-secondary;
        font-size: 1.6rem;
        height: 54rem;

        @include sm {
            background-position: 45% 0%;
        }

        @include md {
            background-position: 45% 0%;
        }

        @include lg {
            background-position: 45% 0%;
        }

        .container {
            align-items: flex-end;
            display: flex;
            height: 54rem;
            max-width: 100%;
            padding: 0;
        }

        &__content {
            background-color: $eclipse;
            background-color: #6e796e;
            height: 9rem;
            padding: 0 25px;
            text-align: center;
            width: 100%;
        }

        &__header {
            font-size: 3.5rem;
            font-style: italic;
        }
    }

    &__header {
        font-size: 3rem;
        text-align: center;
    }

    &__content {
        padding: 30px 0;
    }

    p {
        font-size: 2rem;
        text-align: justify;
        margin: {
            left: auto;
            right: auto;
        }
        max-width: 60rem;
        width: 80%;
    }
}
