// Header styles

// Imports
@import '../../Styles/variables.scss';
@import '../../Styles/mixins.scss';

.header {
    box-shadow: 0px 1px 10px $nero;
    // height: 100px;
    position: relative;
    z-index: 1;
    background-color: #6e796e;
    padding: 20px 0;

    &__wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 15px;
    }

    &__logo {
        width: 15rem;
    }

    .navbar {
        background-color: $riverbed;
        box-shadow: 0px 3px 5px $nero;
        height: 16rem;
        margin-left: -1.5rem;
        overflow: hidden;
        top: 8.9rem;
        transition: height 0.35s ease-in-out;
        position: absolute;
        width: 100%;

        @include md {
            background-color: transparent;
            box-shadow: none;
            display: flex;
            height: auto;
            justify-content: space-between;
            position: relative;
            top: auto;
            width: auto;
            // width: 75%;
        }

        &__hamburger {
            border: solid 2px $riverbed;
            border-radius: 0.6rem;
            cursor: pointer;
            display: flex;
            // display: none;
            align-items: center;
            justify-content: center;
            height: 3rem;
            padding: 0.4rem;
            width: 3rem;

            img {
                fill: red;
            }

            @include md {
                display: none;
            }
        }

        &--closed {
            height: 0;

            @include md {
                height: auto;
            }
        }

        &__link {
            background-color: $riverbed;
            color: $text-secondary;
            display: block;
            font-size: 1.5rem;
            line-height: 4rem;
            padding: {
                left: 1.5rem;
                right: 1.5rem;
            }
            text-decoration: none;
            transition: background-color 0.5s ease-in-out;

            @include md {
                background-color: transparent;
                border-radius: 0.5rem;
                color: $text-primary;
                transition: background-color 0s;
                transition: color 0.5s ease-in-out;
            }

            &:hover {
                background-color: $eclipse;

                @include md {
                    background-color: transparent;
                    color: white;
                }
            }
        }

        &__link.active {
            background-color: $nero;
            color: $goldenyellow;
        }
    }
}
