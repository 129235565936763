// Imports
@import '../../Styles/variables.scss';
@import '../../Styles/mixins.scss';

.home {
    &-hero {
        background-color: $riverbed;
        background-image: url('/images/hero-homepage-bg.jpg');
        background-position: 41% 0%;
        color: $text-secondary;
        font-size: 1.6rem;
        height: 54rem;

        @include sm {
            background-position: 40% 0%;
        }

        @include md {
            background-position: 45%;
            height: 35rem;
        }

        @include lg {
            background-position: 45% 0%;
        }

        .container {
            // align-items: center;
            align-items: flex-end;
            display: flex;
            height: 54rem;
            padding: 0;

            @include md {
                align-items: center;
                height: 35rem;
                justify-content: flex-end;
            }

            @include lg {
                justify-content: flex-end;
            }
        }

        &__content {
            background-color: $eclipse;
            height: 19rem;
            padding: 0 20px;
            text-align: center;
            width: 100%;

            @include md {
                background-color: transparent;
                max-width: 55rem;
            }

            @include lg {
                margin-right: 5rem;
            }
        }

        &__header {
            font-size: 3.5rem;
            font-style: italic;
        }
    }
}

.book-appointment {
    // background-color: $nero;
    background-color: #6e796e;
    padding: 1rem;
    text-align: center;
}

.dental-services {
    padding: 60px 0;

    &__hello {
        text-align: center;
        color: #6e796e;
        font-size: 1.75rem;
        font-weight: 600;
    }

    &__header {
        font-size: 3rem;
        text-align: center;
        font-weight: 600;
    }

    &__blurb {
        font-size: 1.75rem;
        margin: 0 auto 70px;
        max-width: 75%;
        text-align: center;
    }

    &__item {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        text-align: center;
        // width: 100%;
        box-shadow: 0px -3px 3px #c4c7c4;
        border-radius: 30px;
        height: 100%;
        padding: 50px 15px !important;

        @include md {
            // flex-direction: row;
            text-align: left;
        }

        &-header {
            font-family: 'Open Sans';
            font-size: 2rem;
            // font-style: italic;
            font-weight: 600;
            color: #6e796e;
        }
    }

    &__icon {
        align-items: center;
        background-color: $riverbed;
        background-color: #6e796e;
        border-radius: 100%;
        justify-content: center;
        display: flex;
        flex-shrink: 0;
        height: 15rem;
        width: 15rem;
        img {
            max-width: 50%;
        }
    }

    &__details {
        align-self: flex-start;

        @include md {
            padding-left: 30px;
        }
    }

    &__text {
        font-size: 1.75rem;
    }
}

.instagram {
    // background-color: $lightgrey;
    background-color: #b1b8b1;
    color: $text-secondary;
    font-size: 2rem;
    font-style: italic;
    padding: 60px 0;

    &__header {
        // text-shadow: 1px 1px 3px rgb(121, 121, 121);
        font-size: 3rem;
        font-style: italic;
        margin: 0 0 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        a {
            // color: $white;
            color: #6e796e;
            cursor: pointer;
            transition: color 0.5s ease-in-out;
            text-underline-offset: 10px;

            &:hover {
                color: white;
            }
        }
    }

    &__icon {
        width: 75px;
        fill: #6e796e;
    }

    &__items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // justify-content: center;
    }

    &__item {
        // display: inline-flex;
        // flex-direction: column;
        text-align: center;
        margin: 0 auto;
    }

    &__blurb {
        flex-shrink: 0;
        width: 100%;
        flex: 1;
    }
}
