// Global styles

// Imports
@import url('https://fonts.googleapis.com/css?family=Pontano+Sans:300,400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap');
@import './Styles/normalize.scss';
@import './Styles/primeflex.scss';
@import './Styles/variables.scss';
@import './Styles/mixins.scss';

html {
    color: $text-primary;
    font-family: 'Pontano Sans', sans-serif;
    font-size: 62.5%;
}

img,
embed,
object,
video {
    max-width: 100%;
}

.container {
    margin: 0 auto;
    max-width: 1170px;
    padding: 0 15px;
}

div {
    box-sizing: border-box; // Fixes primeflex div wrapping
}

/*** BUTTON STYLES ***/
.btn {
    background-color: $goldenyellow;
    color: $white;
    border-radius: 0.5rem 0.5rem 0.5rem 0;
    display: inline-block;
    font-size: 2.5rem;
    font-style: italic;
    line-height: 4rem;
    padding: 0 20px;
    text-decoration: none;
    text-shadow: 0.1rem 0.1rem 0.3rem $riverbed;
    transition: background-color 0.35s ease-in-out;
    // transition: color .25s ease-in-out;

    &:hover {
        background-color: $nero;
        color: $goldenyellow;
    }
}
