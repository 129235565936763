// Imports
@import '../../Styles/variables.scss';
@import '../../Styles/mixins.scss';

.financing {
    font-size: 2rem;
    min-height: 78vh;
    text-align: center;

    &-hero {
        background-color: $riverbed;
        background-image: url('/images/hero-carecredit.jpg');
        background-position: 50% 0%;
        color: $text-secondary;
        font-size: 1.6rem;
        height: 54rem;

        @include md {
            background-position: 30%;
            height: 35rem;
        }

        @include lg {
            // background-position: 45% 0%;
        }

        .container {
            // align-items: center;
            align-items: flex-end;
            display: flex;
            height: 54rem;
            padding: 0;

            @include md {
                align-items: center;
                height: 35rem;
                justify-content: flex-start;
            }

            @include lg {
                // justify-content: flex-end;
            }
        }

        &__content {
            background-color: $eclipse;
            background-color: #6e796e;
            height: 19rem;
            padding: 0 25px;
            text-align: center;
            width: 100%;

            @include md {
                background-color: transparent;
                color: rgb(2, 54, 2);
                max-width: 55rem;
                width: 450px;
            }

            @include lg {
                margin-left: 5rem;
            }
        }

        &__header {
            font-size: 3.5rem;
            font-style: italic;
        }
    }

    &__main-text {
        padding: 0 3rem;

        @include md {
            margin: 1.5rem auto 1.5rem;
            max-width: 60rem;
        }
    }

    .cc-image {
        margin: 30px 0;
        // max-width: 100px;
        max-height: 10rem;
    }

    .cc-logo {
        width: 70%;
        margin-top: 3rem;
        max-width: 40rem;
    }

    &__bar {
        background-color: $eclipse;
        background-color: #6e796e;
    }
}
