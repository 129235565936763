// Imports
@import '../../Styles/variables.scss';
@import '../../Styles/mixins.scss';

html {
    min-height: 100%;
    position: relative;
}

body {
    padding-bottom: 580px;

    @include md {
        padding-bottom: 270px;
    }
}

.footer {
    background-color: $eclipse;
    // background-color: #6e796e;
    bottom: 0px;
    color: $text-secondary;
    font-size: 1.35rem;
    position: absolute;
    width: 100%;

    &__header {
        // color: $goldenyellow;
        color: #b1b8b1;
        font-size: 2.25rem;
        margin-bottom: 1.5rem;
    }

    &__link {
        color: white;
        text-decoration: none;
        &:hover {
            color: $goldenyellow;
        }
    }

    &__cols {
        display: flex;
        flex-direction: column;
        padding: 40px 0 !important;
        text-align: center;
        // align-items: center;
        // justify-content: space-around;
        @include md {
            flex-direction: row;
            justify-content: space-around;
            text-align: left;
        }
    }

    &__copyright {
        // background-color: $nero;
        background-color: #6e796e;
        color: $lightgrey;

        .container {
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: 1.15rem;

            @include sm {
                flex-direction: row;
                justify-content: space-between;
                padding: {
                    left: 1.5rem;
                    right: 1.5rem;
                }
            }
        }

        a {
            color: $lightgrey;
        }
    }

    #mc_embed_signup form {
        padding: 0;
        input {
            margin: {
                left: auto;
                right: auto;
            }
        }

        @include md {
            input {
                margin: {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}
