// Imports
@import '../../Styles/variables.scss';
@import '../../Styles/mixins.scss';

.error-404 {
    font-size: 2rem;
    min-height: 76vh;
    text-align: center;

    &__header {
        font-size: 5rem;
    }

    &-hero {
        background-color: $riverbed;
        background-image: url('/images/hero-404.jpg');
        background-position: 50% 0%;
        color: $text-secondary;
        font-size: 1.6rem;
        height: 35rem;

        @include md {
            background-position: 30%;
            height: 35rem;
        }

        @include lg {
            // background-position: 45% 0%;
        }

        .container {
            // align-items: center;
            align-items: flex-end;
            display: flex;
            height: 54rem;
            padding: 0;

            @include md {
                align-items: center;
                height: 35rem;
                justify-content: flex-start;
            }

            @include lg {
                // justify-content: flex-end;
            }

        }

        &__content {
            background-color: $eclipse;
            height: 19rem;
            padding: 0 25px;
            text-align: center;
            width: 100%;

            @include md {
                background-color: transparent;
                color: rgb(2, 54, 2);
                max-width: 55rem;
                width: 450px;
            }

            @include lg {
                margin-left: 5rem;
            }
        }

        &__header {
            font-size: 3.5rem;
            font-style: italic;
            text-indent: -999999px;
        }
    }
}
